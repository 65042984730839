"use client";

import isPropValid from "@emotion/is-prop-valid";
import { useUniqueId } from "@faire/web--source/ui/hooks/useUniqueId";
import { parseUnit } from "@faire/web--source/ui/lib";
import React from "react";
import styled, { css } from "styled-components";

import Color from "../Color";
import {
  HIGH_VIS_FILTER,
  highVisSlateComponent,
} from "../utils/highVisSlateComponent";

export const SVGIcon: React.FC<SVGIcon.IProps & React.RefAttributes<SVGElement>> = (
    {
      children,
      className,
      titleAccess,
      width,
      height,
      margin,
      viewBox = "0 0 24 24",
      component: Component,
      ref,
      ...rest
    }
  ) => {
    const labelId = useUniqueId("titleAccess-");
    return (
      <StyledSvg
        as={Component}
        ref={ref}
        className={className}
        focusable="false"
        viewBox={viewBox}
        aria-labelledby={titleAccess ? labelId : null}
        aria-hidden={titleAccess ? null : "true"}
        role={titleAccess ? "img" : "presentation"}
        $margin={margin}
        // width/height exist in SVGIcon but we want to only pass as styles
        $width={width}
        $height={height}
        {...rest}
      >
        {children}
        {titleAccess ? <title id={labelId}>{titleAccess}</title> : null}
      </StyledSvg>
    );
  };

interface StyledSvgProps {
  $width?: string;
  $height?: string;
  $margin?: string;
  $fontSize?: string | number;
  $color?: string;
  $fill?: string;
}

const generateSvgStyles = ({
  $color = "inherit",
  $fill = "currentColor",
  $fontSize,
  $width = "1em",
  $height = "1em",
  $margin = "0",
}: StyledSvgProps) => {
  if ($width === "" || $height === "") {
    // eslint-disable-next-line no-console
    console.error("Icon width/height can't be an empty string.");
  }

  return css`
    display: inline-block;
    color: ${
      // @ts-expect-error FIXME(implicitAny): https://faire.link/no-implicit-any
      Color[$color] ?? $color
    };
    fill: ${$fill};
    flex-shrink: 0;
    font-size: ${$fontSize};
    width: ${parseUnit($width)};
    height: ${parseUnit($height)};
    margin: ${$margin};
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    user-select: none;
  `;
};

const StyledSvg = styled("svg").withConfig({
  shouldForwardProp: (prop) => isPropValid(prop),
})<StyledSvgProps>`
  ${({ $margin, $height, $width, fill, color, fontSize }) =>
    generateSvgStyles({
      $margin: $margin,
      $width: $width,
      $height: $height,
      $fill: fill,
      $color: color,
      $fontSize: fontSize,
    })}
  ${highVisSlateComponent(HIGH_VIS_FILTER.COMPONENT)}
`;

export namespace SVGIcon {
  export interface IProps
    extends Omit<React.SVGProps<SVGSVGElement>, "ref" | "direction"> {
    /**
     * The component used for the root node.
     * Either a string to use a DOM element or a component.
     */
    component?: React.ElementType;
    /**
     * Provides a human-readable title for the element that contains it.
     * Keep this value empty if the icon is decorative only
     * https://www.w3.org/TR/SVG-access/#Equivalent
     */
    titleAccess?: string;
    /**
     * The height of the SVG component element.
     * Override to only allow string values.
     */
    height?: string;
    /**
     * The width of the SVG component element.
     * Override to only allow string values.
     */
    width?: string;
    /**
     * The margin of the SVG component element.
     */
    margin?: string;
  }
}
